import { ExpertiseSectionMock } from '~/types/mock'

const CustomEnterpriseSoftwareDevBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_62',
    title: 'Competitive advantage',
    description:
      'Tailored solutions designed to meet specific business needs enable companies to differentiate themselves and stand out in a crowded market.',
  },
  {
    icon: 'spriteIcon_91',
    title: 'Scalability and flexibility',
    description:
      'These solutions are built to accommodate growth and adapt to evolving business requirements, allowing companies to expand their operations seamlessly.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Cost efficiency',
    description:
      'Investing in these services yields significant long-term savings, enabling businesses to allocate resources strategically and drive overall profitability.',
  },
  {
    icon: 'spriteIcon_74',
    title: 'Integration capabilities',
    description:
      'These software services enable smooth data flow, ensuring compatibility and enhancing operational efficiency across the entire organizational infrastructure.',
  },
  {
    icon: 'spriteIcon_92',
    title: 'Long-term ROI',
    description:
      'Custom enterprise software development services deliver long-term returns on investment (ROI) by aligning advanced technology solutions with business goals.',
  },
  {
    icon: 'spriteIcon_71',
    title: 'Data security and control',
    description:
      'These services implement cutting-edge security protocols, ensuring data integrity, confidentiality, and compliance with industry regulations.',
  },
]

export default CustomEnterpriseSoftwareDevBenefits
