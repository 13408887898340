import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useState } from 'react'

import clutchRating from '~/assets/images/homepage/clutch-rating.svg?file'
import iconLocation from '~/assets/images/icons/icon-location.svg?file'
import * as containerButtonStyles from '~/components/buttons/Button/Button.module.scss'
import { cx } from '~/utils'

import * as containerStyles from './ClientVideoQuotes.module.scss'

type ClientVideoQuotesProps = {
  name: string
  link: string
  position: string
  location?: string
  videoLink?: string
  image: IGatsbyImageData | string | undefined
  quote: { text: string }[]
}

const ClientVideoQuotes = ({
  quote,
  image,
  name,
  position,
  location,
  link,
  videoLink,
}: ClientVideoQuotesProps) => {
  const [showVideo, setShowVideo] = useState(false)

  const handleChange = () => {
    setShowVideo(() => true)
  }

  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <div className={containerStyles.clientVideoQuotes__clientPhoto}>
          {!showVideo && (
            <>
              {React.isValidElement(image) ? (
                image
              ) : (
                <GatsbyImage
                  image={image as IGatsbyImageData}
                  alt={`${name} | Codica`}
                  title={name}
                  loading="lazy"
                />
              )}
              <button
                type="button"
                name="Play video"
                aria-label="Play video"
                onClick={handleChange}
                onKeyDown={handleChange}
                className={containerStyles.clientVideoQuotes__playBtn}
              >
                Play video
              </button>
            </>
          )}
          <div className={containerStyles.clientVideoQuotes__videoYoutube}>
            {showVideo && (
              <iframe
                src={videoLink}
                title="Video review"
                width="100%"
                height="100%"
                loading="lazy"
                allowFullScreen
              />
            )}
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-6">
        {quote.map(({ text }) => (
          <p key={text} className={containerStyles.clientVideoQuotes__quote}>
            {text}
          </p>
        ))}
        <div className={containerStyles.clientVideoQuotes__flex}>
          <div className={containerStyles.clientVideoQuotes__name}>{name}</div>
          <div className={containerStyles.clientVideoQuotes__location}>
            <img
              src={iconLocation}
              alt="Icon location | Codica"
              title="Icon location"
              width="16px"
              height="16px"
              loading="lazy"
            />
            {location}
          </div>
        </div>
        <div className={containerStyles.clientVideoQuotes__position}>
          {position}
        </div>
        <div>
          <img
            src={clutchRating}
            alt="Clutch Rating | Codica"
            title="Codica Clutch Rating"
            width="189px"
            height="26px"
            loading="lazy"
          />
        </div>
        <Link
          to={`/case-studies/${link}/`}
          className={cx(
            containerButtonStyles.button,
            containerButtonStyles.button__red,
            containerStyles.clientVideoQuotes__link,
          )}
        >
          View case study
        </Link>
      </div>
    </div>
  )
}

export default ClientVideoQuotes
