import { DataTabList } from '~/types/data-array'

const CustomEnterpriseSoftwareDevTabList: DataTabList[] = [
  {
    name: 'Project time tracking',
  },
  {
    name: 'Collaboration platform',
  },
]

export default CustomEnterpriseSoftwareDevTabList
