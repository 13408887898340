// extracted by mini-css-extract-plugin
export var customEnterpriseDevBenefitsSection = "P_hD";
export var customEnterpriseDevCallToActionSection = "P_hy";
export var customEnterpriseDevCaseSection = "P_hK";
export var customEnterpriseDevCostSection = "P_hF";
export var customEnterpriseDevExperienceSection = "P_hJ";
export var customEnterpriseDevExpertiseSection = "P_hH";
export var customEnterpriseDevIndustriesSection = "P_hx";
export var customEnterpriseDevPracticesSection = "P_hG";
export var customEnterpriseDevPrimeSection = "P_hv";
export var customEnterpriseDevProcessSection = "P_hB";
export var customEnterpriseDevQuotesSection = "P_hL";
export var customEnterpriseDevServicesSection = "P_hw";
export var customEnterpriseDevSolutionSection = "P_hz";
export var customEnterpriseDevTechSection = "P_hC";