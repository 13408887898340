import { cx } from '~/utils'

import ServicesTechStackItem from '../ServicesTechStackItem'
import * as containerStylesWrapper from '../ServicesTechStackWrapper/ServicesTechStackWrapper.module.scss'

import * as containerStyles from './ServicesTechStackIntegrationsWrapper.module.scss'

const ServicesTechStackIntegrationsWrapper = () => (
  <div
    className={cx(
      'container container-md',
      containerStylesWrapper.servicesTechStack__wrapper,
    )}
  >
    <div
      className={cx('row', containerStyles.servicesTechStack__integrationsRow)}
    >
      <div className={containerStyles.servicesTechStack__integrationsBox}>
        <div className={containerStyles.servicesTechStack__integrationsTitle}>
          Payments
        </div>
        <ServicesTechStackItem
          techStack={[
            { icon: 'stripe', name: 'Stripe' },
            { icon: 'paypal', name: 'Paypal' },
            { icon: 'mollie', name: 'Mollie' },
          ]}
        />
      </div>

      <div className={containerStyles.servicesTechStack__integrationsBox}>
        <div className={containerStyles.servicesTechStack__integrationsTitle}>
          Analytics
        </div>
        <ServicesTechStackItem
          techStack={[
            { icon: 'googleanalytics', name: 'Google Analytics' },
            { icon: 'hotjar', name: 'Hotjar' },
            { icon: 'hubspot', name: 'HubSpot' },
          ]}
        />
      </div>

      <div className={containerStyles.servicesTechStack__integrationsBox}>
        <div className={containerStyles.servicesTechStack__integrationsTitle}>
          Email
        </div>
        <ServicesTechStackItem
          techStack={[
            { icon: 'mailchimp', name: 'MailChimp' },
            { icon: 'sendgrid', name: 'SendGrid' },
          ]}
        />
      </div>

      <div className={containerStyles.servicesTechStack__integrationsBox}>
        <div className={containerStyles.servicesTechStack__integrationsTitle}>
          Monitoring
        </div>
        <ServicesTechStackItem
          techStack={[
            { icon: 'sentry', name: 'Sentry' },
            { icon: 'prometheus', name: 'Prometheus' },
            { icon: 'newrelic', name: 'New Relic' },
            { icon: 'grafana', name: 'Grafana' },
            { icon: 'pagerduty', name: 'PagerDuty' },
            { icon: 'skylight', name: 'Skylight' },
          ]}
        />
      </div>
    </div>
  </div>
)

export default ServicesTechStackIntegrationsWrapper
