// extracted by mini-css-extract-plugin
export var sprite3DIcons = "cg_n5";
export var sprite3DIcons__sprite = "cg_n6";
export var sprite3DIcons__sprite3DIcon_1 = "cg_n7";
export var sprite3DIcons__sprite3DIcon_10 = "cg_pj";
export var sprite3DIcons__sprite3DIcon_11 = "cg_pk";
export var sprite3DIcons__sprite3DIcon_12 = "cg_pl";
export var sprite3DIcons__sprite3DIcon_13 = "cg_pm";
export var sprite3DIcons__sprite3DIcon_14 = "cg_pn";
export var sprite3DIcons__sprite3DIcon_15 = "cg_pp";
export var sprite3DIcons__sprite3DIcon_16 = "cg_pq";
export var sprite3DIcons__sprite3DIcon_17 = "cg_pr";
export var sprite3DIcons__sprite3DIcon_18 = "cg_ps";
export var sprite3DIcons__sprite3DIcon_19 = "cg_pt";
export var sprite3DIcons__sprite3DIcon_2 = "cg_n8";
export var sprite3DIcons__sprite3DIcon_20 = "cg_pv";
export var sprite3DIcons__sprite3DIcon_21 = "cg_pw";
export var sprite3DIcons__sprite3DIcon_22 = "cg_px";
export var sprite3DIcons__sprite3DIcon_3 = "cg_n9";
export var sprite3DIcons__sprite3DIcon_4 = "cg_pb";
export var sprite3DIcons__sprite3DIcon_5 = "cg_pc";
export var sprite3DIcons__sprite3DIcon_6 = "cg_pd";
export var sprite3DIcons__sprite3DIcon_7 = "cg_pf";
export var sprite3DIcons__sprite3DIcon_8 = "cg_pg";
export var sprite3DIcons__sprite3DIcon_9 = "cg_ph";