// extracted by mini-css-extract-plugin
export var discoverWorksItem = "c3_wH";
export var discoverWorksItem__arrowLink = "c3_wV";
export var discoverWorksItem__bg = "c3_wL";
export var discoverWorksItem__clientImg = "c3_wY";
export var discoverWorksItem__clientName = "c3_wZ";
export var discoverWorksItem__clientPosition = "c3_w0";
export var discoverWorksItem__gradientAfricar = "c3_xd";
export var discoverWorksItem__gradientBabel = "c3_xh";
export var discoverWorksItem__gradientCaker = "c3_w6";
export var discoverWorksItem__gradientDv = "c3_xc";
export var discoverWorksItem__gradientImpact = "c3_w2";
export var discoverWorksItem__gradientIx = "c3_xb";
export var discoverWorksItem__gradientMalleni = "c3_w3";
export var discoverWorksItem__gradientPmk = "c3_w8";
export var discoverWorksItem__gradientPmkDesktop = "c3_w9";
export var discoverWorksItem__gradientRew = "c3_w1";
export var discoverWorksItem__gradientTimebot = "c3_xg";
export var discoverWorksItem__gradientTradeaboat = "c3_w4";
export var discoverWorksItem__gradientTradervs = "c3_w5";
export var discoverWorksItem__gradientVaria = "c3_xf";
export var discoverWorksItem__gradientWowner = "c3_w7";
export var discoverWorksItem__header = "c3_wJ";
export var discoverWorksItem__imageHover = "c3_wK";
export var discoverWorksItem__mobileImage = "c3_wW";
export var discoverWorksItem__results = "c3_wP";
export var discoverWorksItem__results_head = "c3_wQ";
export var discoverWorksItem__results_num = "c3_wR";
export var discoverWorksItem__results_text = "c3_wS";
export var discoverWorksItem__results_textOnly = "c3_wT";
export var discoverWorksItem__review = "c3_wX";
export var discoverWorksItem__title = "c3_wM";
export var discoverWorksItem__wrapper = "c3_wN";