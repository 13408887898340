import { StaticImage, getImage } from 'gatsby-plugin-image'

import { CommonImageProps } from '~/types/common-props'
import { WorksTabsContent } from '~/types/data-array'
import { fileToImageLikeData } from '~/utils'
import useCustomEnterpriseSoftwareDevelopmentStaticQuery from '~/views/Services/CustomEnterpriseSoftwareDevelopment/useCustomEnterpriseSoftwareDevelopmentStaticQuery'

const photoClientDV = '../../../assets/images/avatars/clients/client-dv.png'
const photoCodicaArpi = '../../../assets/images/avatars/PM-Arpi.png'

const imageProps: CommonImageProps = {
  width: 50,
  height: 50,
}

const CustomEnterpriseSoftwareDevTabContent = (): WorksTabsContent[] => {
  const query = useCustomEnterpriseSoftwareDevelopmentStaticQuery()

  const portfolioTimebot = getImage(fileToImageLikeData(query.portfolioTimebot))
  const portfolioDV = getImage(fileToImageLikeData(query.portfolioDV))

  const tabContent = [
    {
      key: 'Project time tracking',
      gradient: 'gradientTimebot',
      image: portfolioTimebot,
      header: 'Time tracking software',
      title:
        'TimeBot is a Slack bot in conjunction with the admin panel that serves as the project management tool. It automates monitoring the workflow and evaluation of the performance, boosting the team’s productivity.',
      resultsLeft: 'Delivered a high-powered time-tracking tool.',
      resultsRight: 'Implemented a transparent reporting system.',
      link: 'timebot-for-slack',
      review:
        '“As a project manager, I firmly believe that time tracking is the key to keeping track of the team’s performance and its analysis, and setting accurate project estimates for future iterations. And that is what TimeBot is great at. By one click, I can access overall performance statistics on individuals, teams, and projects for any time period and at any time.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoCodicaArpi}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Arpi',
      clientPosition: 'Project Manager at Codica',
    },
    {
      key: 'Collaboration platform',
      gradient: 'gradientDv',
      image: portfolioDV,
      header: 'Collaboration platform',
      title:
        'Digital Village is a platform in Australia that brings a job network of digital professionals together to develop software and solve business technology issues.',
      resultsLeft: 'Delivered a team collaboration functionality.',
      resultsRight: 'Created simple and clear UI and UX.',
      link: 'collaboration-marketplace-and-smart-contract-system',
      review:
        '“The outcome of our Beta version is perfect for our needs and the technology foundation is there for future rapid growth. A partnership of trust and respect is reciprocated and we look forward to working with Codica into the future.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientDV}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Jason Hardie',
      clientPosition: 'Founder of The Digital Village Australia',
    },
  ]

  return tabContent
}

export default CustomEnterpriseSoftwareDevTabContent
