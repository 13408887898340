import { useEffect } from 'react'

import { DataContentProps } from '~/types/helper'
import { ServicesProcessMock } from '~/types/mock'
import { cx } from '~/utils'

import * as containerStyles from './ServicesProcess.module.scss'

const ServicesProcess = ({
  dataContent,
}: DataContentProps<ServicesProcessMock>) => {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(
        `.${containerStyles.servicesProcessSection__num}`,
      )

      elements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top
        const windowHeight = window.innerHeight

        if (elementTop < windowHeight) {
          element.classList.add(containerStyles.servicesProcessSection__animate)
        }
      })
    }

    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className="container container-md">
      <div
        className={cx(
          'row justify-content-between',
          containerStyles.servicesProcessSection__row,
        )}
      >
        {dataContent.map(({ num, title, descr }) => (
          <div
            key={num}
            className={containerStyles.servicesProcessSection__wrapper}
          >
            <div className={containerStyles.servicesProcessSection__num}>
              {num}
            </div>
            <div className={containerStyles.servicesProcessSection__infoCard}>
              <div className={containerStyles.servicesProcessSection__title}>
                {title}
              </div>
              <div className={containerStyles.servicesProcessSection__descr}>
                {descr}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default ServicesProcess
