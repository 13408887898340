import Title from '~/components/shared/Title'
import { useAddClassNameWhenVisible } from '~/hooks/useAddClassNameWhenVisible'
import * as containerStylesSpriteIcons from '~/styles/modules/SpriteIcons.module.scss'
import { DataContentProps, WithOptional } from '~/types/helper'
import { ExpertiseSectionMock } from '~/types/mock'
import { cx } from '~/utils'

import * as containerStyles from './ServicesIconSmallCard.module.scss'

interface ServicesIconSmallCardProps
  extends DataContentProps<WithOptional<ExpertiseSectionMock, 'icon'>> {
  isBorder?: boolean
  isColSm?: boolean
  isColMd?: boolean
  isLeft?: boolean
}

const ServicesIconSmallCard = ({
  dataContent,
  isBorder,
  isColSm,
  isColMd,
  isLeft,
}: ServicesIconSmallCardProps) => (
  <div className="container container-md">
    <div
      className={cx(
        'row',
        isLeft ? 'justify-content-start' : 'justify-content-center',
      )}
    >
      {dataContent.map(({ icon, link, title, description }) => {
        const { ref } = useAddClassNameWhenVisible<HTMLDivElement>({
          targetClassName:
            containerStylesSpriteIcons[`spriteIconsWrapper__${icon}`],
          loadedClassName:
            containerStylesSpriteIcons.spriteIconsWrapper__sprite,
        })

        return (
          <div
            ref={ref}
            key={title}
            className={cx(
              containerStyles.servicesIconSmallCard,
              isColSm ? 'col-12 col-sm-6 col-md-3' : 'col-12 col-md-4',
              isColMd && 'col-12 col-md-6',
            )}
          >
            <div
              className={cx(
                isBorder
                  ? containerStyles.servicesIconSmallCard__cardBorder
                  : containerStyles.servicesIconSmallCard__card,
                isColMd && containerStyles.servicesIconSmallCard__fullWidth,
              )}
            >
              {icon && (
                <div
                  className={cx(
                    isBorder &&
                      containerStyles.servicesIconSmallCard__iconCardBorder,
                    containerStyles.servicesIconSmallCard__icon,
                    containerStylesSpriteIcons.spriteIconsWrapper,
                    containerStylesSpriteIcons[`spriteIconsWrapper__${icon}`],
                  )}
                />
              )}

              <Title
                link={link}
                headingLevel="h3"
                className={cx(
                  isColSm && containerStyles.servicesIconSmallCard__headSm,
                  containerStyles.servicesIconSmallCard__head,
                )}
              >
                {title}
              </Title>

              {description && (
                <div className={containerStyles.servicesIconSmallCard__text}>
                  {description}
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

export default ServicesIconSmallCard
