// extracted by mini-css-extract-plugin
export var servicesDevExpertiseCompany = "c5_xH";
export var servicesDevExpertiseCompany__card = "c5_xJ";
export var servicesDevExpertiseCompany__emoji = "c5_xL";
export var servicesDevExpertiseCompany__maxWidth140 = "c5_xN";
export var servicesDevExpertiseCompany__maxWidth180 = "c5_xP";
export var servicesDevExpertiseCompany__maxWidth190 = "c5_xQ";
export var servicesDevExpertiseCompany__maxWidth270 = "c5_xR";
export var servicesDevExpertiseCompany__maxWidth310 = "c5_xS";
export var servicesDevExpertiseCompany__text = "c5_xM";
export var servicesDevExpertiseCompany__title = "c5_xK";