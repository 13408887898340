import Title from '~/components/shared/Title/'
import { useAddClassNameWhenVisible } from '~/hooks/useAddClassNameWhenVisible'
import * as containerStylesSpriteIcons from '~/styles/modules/Sprite3DIcons.module.scss'
import { DataContentProps, WithOptional } from '~/types/helper'
import { ExpertiseSectionMock } from '~/types/mock'
import { cx } from '~/utils'

import * as containerStyles from './Service3DIconCard.module.scss'

interface Service3DIconCardProps
  extends DataContentProps<WithOptional<ExpertiseSectionMock, 'title'>> {}

const Service3DIconCard = ({ dataContent }: Service3DIconCardProps) => (
  <div className="container container-md">
    <div className="row justify-content-center">
      {dataContent.map(({ icon, link, title, description }, index) => {
        const { ref } = useAddClassNameWhenVisible<HTMLDivElement>({
          targetClassName: containerStylesSpriteIcons[`sprite3DIcons__${icon}`],
          loadedClassName: containerStylesSpriteIcons.sprite3DIcons__sprite,
        })

        return (
          <div
            ref={ref}
            key={index}
            className={cx(
              containerStyles.service3DIconCard,
              'col-12 col-md-6 col-lg-4',
            )}
          >
            <div className={containerStyles.service3DIconCard__card}>
              <div
                className={cx(
                  containerStyles.service3DIconCard__icon,
                  containerStylesSpriteIcons.sprite3DIcons,
                  containerStylesSpriteIcons[`sprite3DIcons__${icon}`],
                )}
              />

              <Title
                link={link}
                headingLevel="h3"
                className={containerStyles.service3DIconCard__title}
              >
                {title}
              </Title>

              <div className={containerStyles.service3DIconCard__description}>
                {description}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

export default Service3DIconCard
