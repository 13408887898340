import { NavTechStackMock, TechStackMock } from '~/types/mock'

export const NavTechStack: NavTechStackMock[] = [
  {
    title: 'Front-end',
  },
  {
    title: 'Back-end',
  },
  {
    title: 'DevOps',
  },
  {
    title: 'Databases',
  },
  {
    title: 'Infrastructure',
  },
  {
    title: 'Integrations',
  },
]

export const TechStack: TechStackMock[] = [
  {
    title: 'Front-end',
    techStack: [
      { icon: 'javascript', name: 'JavaScript' },
      { icon: 'typescript', name: 'TypeScript' },
      { icon: 'react', name: 'React' },
      { icon: 'redux', name: 'Redux' },
      { icon: 'nextjs', name: 'Next.js' },
      { icon: 'vuejs', name: 'Vue.js' },
      { icon: 'html', name: 'HTML5' },
      { icon: 'css', name: 'CSS3' },
      { icon: 'gatsby', name: 'Gatsby' },
    ],
  },
  {
    title: 'Back-end',
    techStack: [
      { icon: 'ruby', name: 'Ruby' },
      { icon: 'ror', name: 'Ruby on Rails' },
      { icon: 'strapi', name: 'Strapi' },
      { icon: 'nodejs', name: 'Node.js' },
      { icon: 'nestjs', name: 'Nest.js' },
    ],
  },
  {
    title: 'DevOps',
    techStack: [
      { icon: 'linux', name: 'Linux' },
      { icon: 'ansible', name: 'Ansible' },
      { icon: 'docker', name: 'Docker' },
      { icon: 'gitlab', name: 'Gitlab CI' },
      { icon: 'terraform', name: 'Terraform' },
      { icon: 'packer', name: 'Packer' },
      { icon: 'vagrant', name: 'Vagrant' },
    ],
  },
  {
    title: 'Databases',
    techStack: [
      { icon: 'postgresql', name: 'PostgreSQL' },
      { icon: 'mysql', name: 'MySQL' },
      { icon: 'mongodb', name: 'Mongo DB' },
      { icon: 'redis', name: 'Redis' },
      { icon: 'firebase', name: 'Firebase' },
      { icon: 'elasticsearch', name: 'ElasticSearch' },
    ],
  },
  {
    title: 'Infrastructure',
    techStack: [
      { icon: 'aws', name: 'AWS' },
      { icon: 'digitalocean', name: 'Digital Ocean' },
      { icon: 'heroku', name: 'Heroku' },
      { icon: 'linode', name: 'Linode' },
    ],
  },
]
