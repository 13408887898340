import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'

import DiscoverWorksItem from '~/components/items/DiscoverWorksItem'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { DataTabList, WorksTabsContent } from '~/types/data-array'
import { DataContentProps } from '~/types/helper'

import './DiscoverWorksTabs.scss'

const formatKey = (name: string) => kebabCase(name)

interface DiscoverWorksTabsProps extends DataContentProps<WorksTabsContent> {
  dataTabList: DataTabList[]
}

const DiscoverWorksTabs = ({
  dataTabList,
  dataContent,
}: DiscoverWorksTabsProps) => {
  const firstNav = dataTabList[0]

  return (
    <LazyHydrate whenVisible>
      <div className="discoverWorksTabs">
        <Tab.Container
          id="discoverWorksTabs"
          transition={false}
          defaultActiveKey={formatKey(firstNav.name)}
        >
          <div className="container container-md">
            <div className="row">
              <div className="col-12">
                <Nav variant="tabs">
                  {dataTabList.map(({ name }) => (
                    <Nav.Link eventKey={formatKey(name)} key={formatKey(name)}>
                      {name}
                    </Nav.Link>
                  ))}
                  <Link
                    role="tab"
                    to="/case-studies/"
                    className="nav-link nav-link__red"
                  >
                    View all our works
                  </Link>
                </Nav>
              </div>
            </div>
          </div>

          <Tab.Content>
            {dataContent.map(
              ({
                key,
                gradient,
                image,
                header,
                title,
                resultsLeftTitle,
                resultsLeft,
                resultsRightTitle,
                resultsRight,
                link,
                review,
                clientPhoto,
                clientName,
                clientPosition,
              }) => (
                <Tab.Pane eventKey={formatKey(key)} key={formatKey(key)}>
                  <DiscoverWorksItem
                    header={header}
                    gradient={gradient}
                    image={image}
                    title={title}
                    resultsLeftTitle={resultsLeftTitle}
                    resultsLeft={resultsLeft}
                    resultsRightTitle={resultsRightTitle}
                    resultsRight={resultsRight}
                    link={link}
                    review={review}
                    clientPhoto={clientPhoto}
                    clientName={clientName}
                    clientPosition={clientPosition}
                  />
                </Tab.Pane>
              ),
            )}
          </Tab.Content>
        </Tab.Container>
      </div>
    </LazyHydrate>
  )
}

export default DiscoverWorksTabs
