import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'

import LazyHydrate from '~/components/shared/LazyHydrate'
import { NavTechStack, TechStack } from '~/mock/tech-stack/TechStackGlobal'

import ServicesTechStackIntegrationsWrapper from './components/ServicesTechStackIntegrationsWrapper'
import ServicesTechStackWrapper from './components/ServicesTechStackWrapper'

import './ServicesTechStackSection.scss'

const formatKey = (title: string) => title.toLowerCase().replace(/\s/g, '-')
const firstNavTechStack = NavTechStack[0]

const ServicesTechStackSection = () => (
  <LazyHydrate whenVisible>
    <div className="servicesTechStackSection">
      <Tab.Container
        id="techStack"
        transition={false}
        defaultActiveKey={formatKey(firstNavTechStack.title)}
      >
        <div className="container container-md">
          <div className="row">
            <div className="col-12">
              <Nav variant="tabs">
                {NavTechStack.map(({ title }) => (
                  <Nav.Link eventKey={formatKey(title)} key={title}>
                    {title}
                  </Nav.Link>
                ))}
              </Nav>
            </div>
          </div>
        </div>

        <Tab.Content>
          {TechStack.map(({ title, techStack }) => (
            <Tab.Pane eventKey={formatKey(title)} key={title}>
              <ServicesTechStackWrapper title={title} techStack={techStack} />
            </Tab.Pane>
          ))}
          <Tab.Pane eventKey="integrations">
            <ServicesTechStackIntegrationsWrapper />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  </LazyHydrate>
)

export default ServicesTechStackSection
