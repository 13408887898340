import { ExpertiseSectionMock } from '~/types/mock'

const CustomEnterpriseSoftwareDevSolutions: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_49',
    title: 'CRM solutions',
    description:
      'With seamless integration and intuitive features, customer relationship management (CRM) solutions streamline interactions, foster customer loyalty, and drive sustainable growth.',
  },
  {
    icon: 'spriteIcon_46',
    title: 'BI solutions',
    description:
      'Business intelligence (BI) solutions gather, analyze, and present valuable insights from vast business data, enabling businesses to make informed strategic choices.',
  },
  {
    icon: 'spriteIcon_33',
    title: 'CMS solutions',
    description:
      'Content management system (CMS) solutions provide a user-friendly interface, enabling businesses to collaborate efficiently and deliver engaging experiences to their audience.',
  },
  {
    icon: 'spriteIcon_21',
    title: 'ERP solutions',
    description:
      'Enterprise resource planning (ERP) solutions centralize data, streamline workflows, and enable real-time collaboration, facilitating scalable business growth.',
  },
  {
    icon: 'spriteIcon_69',
    title: 'HRM solutions',
    description:
      'From recruitment to performance management, human resource management (HRM) solutions provide a comprehensive suite of tools to attract, retain, and develop top talent.',
  },
  {
    icon: 'spriteIcon_42',
    title: 'PM solutions',
    description:
      'With robust features, project management (PM) solutions empower teams to collaborate effectively, meet deadlines, and deliver successful outcomes.',
  },
  {
    icon: 'spriteIcon_65',
    title: 'EAM solutions',
    description:
      'Using enterprise asset management (EAM) solutions, you can track asset performance, streamline maintenance workflows, and ensure regulatory compliance.',
  },
]

export default CustomEnterpriseSoftwareDevSolutions
