import { ExpertiseSectionMock } from '~/types/mock'

const CustomEnterpriseSoftwareDevExperience: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_61',
    title: 'Custom solutions',
    description:
      'We have established ourselves as a go-to development partner with a track record of delivering custom solutions that perfectly align with clients’ unique business needs.',
  },
  {
    icon: 'spriteIcon_47',
    title: 'Dedicated development team',
    description:
      'With our expert development team, we excel in turning unique business challenges into tailor-made digital solutions, delivering exceptional user experiences.',
  },
  {
    icon: 'spriteIcon_56',
    title: 'User experience focus',
    description:
      'Our reliable team embodies excellence, innovation, and client satisfaction for businesses seeking exceptional tailored enterprise software solutions.',
  },
  {
    icon: 'spriteIcon_43',
    title: 'Advanced technology stack',
    description:
      'Armed with cutting-edge technologies, we seamlessly integrate innovative tools and frameworks to build robust, scalable, and future-proof software solutions.',
  },
  {
    icon: 'spriteIcon_15',
    title: 'Agile methodology',
    description:
      'Employing agile methods, our specialists ensure a streamlined and collaborative approach, allowing for an iterative development process and rapid deployment.',
  },
  {
    icon: 'spriteIcon_88',
    title: 'Post-development support',
    description:
      'The Codica team is committed to offering continuous maintenance, updates, and troubleshooting, ensuring the software solution stays ahead of the curve.',
  },
]

export default CustomEnterpriseSoftwareDevExperience
