import { ExpertiseSectionMock } from '~/types/mock'

const CustomEnterpriseSoftwareDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'Technology consulting',
    description:
      'Through comprehensive analysis and strategic guidance, we help identify the most suitable tech solutions and roadmaps, ensuring a solid foundation for business success.',
  },
  {
    icon: 'sprite3DIcon_7',
    title: 'Data management strategies',
    description:
      'With our data governance, security, and analytics expertise, the Codica team empowers businesses to make data-driven decisions and unlock valuable insights.',
  },
  {
    icon: 'sprite3DIcon_11',
    title: 'Custom enterprise development',
    description:
      'Employing the latest technologies and best practices, our skilled developers and designers craft secure and user-friendly solutions that align with each client’s business needs.',
  },
  {
    icon: 'sprite3DIcon_4',
    title: 'Software integration',
    description:
      'Our expert team specializes in seamlessly integrating new software systems with existing infrastructures, ensuring smooth data flow and eliminating inefficiencies.',
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Support and maintenance',
    description:
      'By proactively monitoring and maintaining the software, Codica’s specialists ensure businesses can focus on their core activities, knowing their product is in safe hands.',
  },
]

export default CustomEnterpriseSoftwareDevServices
