import * as containerStyles from './TableCostCustomEnterpriseSoftwareDev.module.scss'

const TableCostCustomEnterpriseSoftwareDev = () => (
  <div className={containerStyles.tableCostCustomEnterpriseSoftwareDev}>
    <table
      className={
        containerStyles.tableCostCustomEnterpriseSoftwareDev__tableWrapper
      }
    >
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Simple app</th>
          <th scope="col">Medium complexity app</th>
          <th scope="col">Complex app</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Cost</td>
          <td>$40-$80k USD</td>
          <td>$80-$120k USD</td>
          <td>$120-$200k+ USD</td>
        </tr>
        <tr>
          <td>Estimate</td>
          <td>2-3 month</td>
          <td>3-6 month</td>
          <td>6-9+ month</td>
        </tr>
        <tr>
          <td>Features</td>
          <td>
            1 platform support, basic features, simple UI (user interface)
          </td>
          <td>
            1 platform support, more complicated functionality, custom UI
            details added
          </td>
          <td>
            2 platforms support, high-level advanced features, custom-made UI
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default TableCostCustomEnterpriseSoftwareDev
