import { DataContentProps } from '~/types/helper'
import { DevExpertiseCompanyMock } from '~/types/mock'
import { cx } from '~/utils'

import * as containerStyles from './ServicesDevExpertiseCompany.module.scss'

const ServicesDevExpertiseCompany = ({
  dataContent,
}: DataContentProps<DevExpertiseCompanyMock>) => (
  <div className="container container-md">
    <div className={cx('row', containerStyles.servicesDevExpertiseCompany)}>
      {dataContent.map(
        ({ title, text, isThreeBlocks, emojiSrc, classNameText }) => (
          <div
            className={`${
              isThreeBlocks ? 'col-12 col-md-4' : 'col-12 col-md-6 col-lg-3'
            }`}
            key={text}
          >
            <div className={containerStyles.servicesDevExpertiseCompany__card}>
              {title && (
                <div
                  className={containerStyles.servicesDevExpertiseCompany__title}
                >
                  {title}
                </div>
              )}
              {emojiSrc && (
                <div
                  className={containerStyles.servicesDevExpertiseCompany__emoji}
                >
                  {emojiSrc}
                </div>
              )}
              <div
                className={cx(
                  containerStyles.servicesDevExpertiseCompany__text,
                  containerStyles[
                    `servicesDevExpertiseCompany__${classNameText}`
                  ],
                )}
              >
                {text}
              </div>
            </div>
          </div>
        ),
      )}
    </div>
  </div>
)

export default ServicesDevExpertiseCompany
