import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const CustomEnterpriseSoftwareDevProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Product discovery',
    descr: (
      <>
        With
        <Link
          to="/services/product-discovery/"
          className="ml5"
          key="keyProductDiscovery"
        >
          product discovery
        </Link>
        , we conduct extensive research and analysis, identify key pain points,
        laying the foundation for a solution.
      </>
    ),
  },
  {
    num: '02',
    title: 'UI/UX design',
    descr: (
      <>
        Our experienced designers deliver quality
        <Link
          to="/services/ui-ux-design/"
          className="ml5"
          key="keyDesignServices"
        >
          UI/UX design services
        </Link>
        , meticulously mapping user journeys and interactions.
      </>
    ),
  },
  {
    num: '03',
    title: 'Product development',
    descr:
      'Following industry best practices and leveraging modern technologies, we develop a scalable, secure, and customizable platform that empowers your enterprise.',
  },
  {
    num: '04',
    title: 'QA testing',
    descr: (
      <>
        At Codica, our expert QA engineers deliver
        <Link
          to="/services/quality-assurance/"
          className="mx5"
          key="keyQaServices"
        >
          QA testing services
        </Link>
        at every stage, employing manual and automated testing methodologies.
      </>
    ),
  },
  {
    num: '05',
    title: 'Product support',
    descr:
      'We offer comprehensive post-launch support to address any concerns, provide timely updates, and proactively suggest enhancements as your business evolves.',
  },
]

export default CustomEnterpriseSoftwareDevProcess
