import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { ReactComponent as ArrowIconBlue } from '~/assets/images/icons/arrow-left-blue.svg'
import { WorksTabsContent } from '~/types/data-array'
import { cx } from '~/utils'

import * as containerStyles from './DiscoverWorksItem.module.scss'

const DiscoverWorksItem = ({
  header,
  title,
  resultsLeft,
  resultsRight,
  resultsLeftTitle,
  resultsRightTitle,
  link,
  review,
  clientPhoto,
  clientName,
  clientPosition,
  gradient,
  image,
}: Omit<WorksTabsContent, 'key'>) => (
  <div className="container container-md">
    <div className="row">
      <div className="col-12 col-lg-6">
        {header && (
          <Link
            to={`/case-studies/${link}/`}
            className={containerStyles.discoverWorksItem__header}
          >
            {header}
          </Link>
        )}
        <div className={containerStyles.discoverWorksItem__title}>{title}</div>
        <div className={containerStyles.discoverWorksItem__results}>
          <div className={containerStyles.discoverWorksItem__results_head}>
            Results
          </div>
          <div className={containerStyles.discoverWorksItem__wrapper}>
            <div>
              {resultsLeftTitle && (
                <div className={containerStyles.discoverWorksItem__results_num}>
                  {resultsLeftTitle}
                </div>
              )}
              <div
                className={
                  resultsLeftTitle
                    ? containerStyles.discoverWorksItem__results_textOnly
                    : containerStyles.discoverWorksItem__results_text
                }
              >
                {resultsLeft}
              </div>
            </div>
            <div>
              {resultsRightTitle && resultsRightTitle !== 'null' && (
                <div className={containerStyles.discoverWorksItem__results_num}>
                  {resultsRightTitle}
                </div>
              )}
              {resultsRightTitle === 'null' && (
                <div
                  className={containerStyles.discoverWorksItem__results_num}
                />
              )}
              <div
                className={
                  resultsRightTitle
                    ? containerStyles.discoverWorksItem__results_textOnly
                    : containerStyles.discoverWorksItem__results_text
                }
              >
                {resultsRight}
              </div>
            </div>
          </div>
        </div>

        <Link
          to={`/case-studies/${link}/`}
          className={containerStyles.discoverWorksItem__arrowLink}
        >
          View case study
          <ArrowIconBlue className="ml5" />
        </Link>
        <div
          className={`d-lg-none col-12 ${containerStyles.discoverWorksItem__mobileImage}`}
        >
          <div
            className={cx(
              containerStyles.discoverWorksItem__bg,
              containerStyles[`discoverWorksItem__${gradient}`],
            )}
          >
            <GatsbyImage
              image={image as IGatsbyImageData}
              alt={`${header} | Codica` || 'Discovery image | Codica'}
              title={`${header}` || 'Discovery image'}
              loading="lazy"
            />
          </div>
        </div>
        <div className={containerStyles.discoverWorksItem__review}>
          {review}
        </div>
        <div className="d-flex align-items-center">
          <div className={containerStyles.discoverWorksItem__clientImg}>
            {clientPhoto}
          </div>
          <div>
            <div className={containerStyles.discoverWorksItem__clientName}>
              {clientName}
            </div>
            <div className={containerStyles.discoverWorksItem__clientPosition}>
              {clientPosition}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`d-none d-lg-block col-lg-6 ${containerStyles.discoverWorksItem__imageHover}`}
      >
        <div
          className={cx(
            containerStyles.discoverWorksItem__bg,
            containerStyles[`discoverWorksItem__${gradient}`],
          )}
        >
          <Link to={`/case-studies/${link}/`} aria-label={link}>
            <GatsbyImage
              image={image as IGatsbyImageData}
              alt={`${header} | Codica` || 'Discovery image | Codica'}
              title={`${header}` || 'Discovery image'}
              loading="lazy"
            />
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default DiscoverWorksItem
