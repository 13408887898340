import { useAddClassNameWhenVisible } from '~/hooks/useAddClassNameWhenVisible'
import { cx } from '~/utils'

import * as containerStyles from './ServicesTechStackItem.module.scss'
import * as containerStylesSprite from './ServicesTechStackSprite.module.scss'

interface TechStack {
  icon: string
  name: string
}

export interface TechStackProps {
  techStack: TechStack[]
}

const ServicesTechStackItem = ({ techStack }: TechStackProps) => {
  const { ref } = useAddClassNameWhenVisible<HTMLDivElement>({
    targetClassName: containerStylesSprite.spriteTechStack,
    loadedClassName: containerStylesSprite.spriteTechStack__sprite,
  })

  return (
    <div ref={ref} className={containerStyles.servicesTechStack__itemWrapper}>
      {techStack.map(({ icon, name }) => (
        <div key={name} className={containerStyles.servicesTechStack__item}>
          <div
            className={cx(
              containerStylesSprite.spriteTechStack,
              containerStylesSprite[`spriteTechStack__${icon}`],
            )}
          />
          <div
            className={cx(
              containerStyles.servicesTechStack__name,
              containerStyles[`servicesTechStack__name_${icon}`],
            )}
          >
            {name}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ServicesTechStackItem
