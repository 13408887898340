import { DevExpertiseCompanyMock } from '~/types/mock'

const CustomEnterpriseSoftwareDevExpertise: DevExpertiseCompanyMock[] = [
  {
    title: '50+',
    text: 'projects delivered',
  },
  {
    title: '9+',
    text: 'years as a reliable custom software development company',
  },
  {
    title: '60+',
    text: 'experts on board',
  },
  {
    title: '$56+',
    text: 'mln raised by our clients',
  },
]

export default CustomEnterpriseSoftwareDevExpertise
