// extracted by mini-css-extract-plugin
export var servicesTechStack__item = "f2_Pw";
export var servicesTechStack__itemWrapper = "f2_Pv";
export var servicesTechStack__name = "f2_Px";
export var servicesTechStack__name_chromedevtools = "f2_PG";
export var servicesTechStack__name_digitalocean = "f2_PD";
export var servicesTechStack__name_elasticsearch = "f2_PB";
export var servicesTechStack__name_gitlab = "f2_PF";
export var servicesTechStack__name_googleCloudPlatform = "f2_PJ";
export var servicesTechStack__name_googleDocs = "f2_PH";
export var servicesTechStack__name_googleanalytics = "f2_Py";
export var servicesTechStack__name_prometheus = "f2_Pz";
export var servicesTechStack__name_ror = "f2_PC";