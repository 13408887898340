import { HomeSpecification } from '~/types/mock'

const CustomEnterpriseSoftwareDevIndustries: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description:
      'We provide software solutions that facilitate efficient management of listings/inventory, secure payment processing, and comprehensive user management features.',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description:
      'Our custom software solutions enable businesses to establish a robust online presence, manage their product catalogs, process orders, and easily track inventory.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description:
      'We integrate software with various travel service providers, enable seamless online bookings, and ensure a hassle-free experience for travelers and travel companies.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description:
      'Our solutions are tailored to meet the unique needs of dealerships, auto manufacturers, and service centers, enabling them to improve customer satisfaction and drive growth.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description:
      'For the insurance industry, our specialists offer custom software solutions that automate policy management, claims processing, and personalized customer interactions.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description:
      'We provide applicant tracking systems, candidate management tools, and integration with job boards and social media platforms, enabling recruiters to streamline workflows.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description:
      'Our solutions help rental companies streamline their operations, improve resource allocation, and enhance customer experience, leading to increased efficiency and profitability.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description:
      'We offer exciting tools for content creation, collaboration, and delivery across multiple platforms, enabling media companies to reach their target audiences effectively.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'Our experts provide secure and scalable software solutions that streamline healthcare operations, improve patient care coordination, and ensure regulatory compliance.',
    link: '/case-studies/fitness-progressive-web-application/',
  },
]

export default CustomEnterpriseSoftwareDevIndustries
