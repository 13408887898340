import { cx } from '~/utils'

import ServicesTechStackItem, {
  TechStackProps,
} from '../ServicesTechStackItem/ServicesTechStackItem.view'

import * as containerStyles from './ServicesTechStackWrapper.module.scss'

interface ServicesTechStackWrapperProps extends TechStackProps {
  title: string
}

const ServicesTechStackWrapper = ({
  title,
  techStack,
}: ServicesTechStackWrapperProps) => (
  <div
    className={cx(
      'container container-md',
      containerStyles.servicesTechStack__wrapper,
    )}
  >
    <div className="row">
      <div className="col-12">
        <div className={containerStyles.servicesTechStack__wrapperTitle}>
          {title}
        </div>
      </div>
      <div className="col-12">
        <ServicesTechStackItem techStack={techStack} />
      </div>
    </div>
  </div>
)

export default ServicesTechStackWrapper
