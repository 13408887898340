import { FAQ } from '~/types/data-array'

const CustomEnterpriseSoftwareDevFAQ: FAQ[] = [
  {
    question: 'What are some common enterprise software applications?',
    answer:
      'There are some enterprise software apps such as enterprise resource planning (ERP) systems, customer relationship management (CRM) systems, human resources management systems (HRMS), supply chain management (SCM) systems, content management systems (CMS), learning management systems (LMS), etc.',
  },
  {
    question: 'What type of custom enterprise software can we develop?',
    answer:
      'Codica team has expertise in developing various types of enterprise software tailored to specific business needs. We create custom enterprise web apps, mobile enterprise apps, e-commerce platforms, CRM systems, integrations, API development, and more.',
  },
  {
    question:
      'What other enterprise software development services do you offer?',
    answer:
      'Codica provides many services, including technology consulting, prototyping and designing, MVP development, data migration, cloud integration, DevOps, third-party system integration, post-launched maintenance and support, and more.',
  },
]

export default CustomEnterpriseSoftwareDevFAQ
