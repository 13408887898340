import { ExpertiseSectionMock } from '~/types/mock'

const CustomEnterpriseSoftwareDevPractices: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_67',
    title: 'Requirements gathering and analysis',
    description:
      'Our dedicated team works closely with you to identify and document all necessary features and functionalities, ensuring the final solution perfectly aligns with your goals.',
  },
  {
    icon: 'spriteIcon_27',
    title: 'Client collaboration and transparency',
    description:
      'We maintain a high level of transparency, providing regular updates, progress reports, and opportunities for feedback, ensuring your satisfaction at every stage.',
  },
  {
    icon: 'spriteIcon_1',
    title: 'Robust architecture and scalability',
    description:
      'Our specialists consider your current and future business needs to develop a software solution that can adapt and scale seamlessly as your business evolves.',
  },
  {
    icon: 'spriteIcon_11',
    title: 'User-centric design',
    description:
      'At Codica, we conduct user research and usability testing to gain insights into your target audience, enabling us to deliver an exceptional user experience.',
  },
]

export default CustomEnterpriseSoftwareDevPractices
